import router from './router'
import Vue from 'vue'
import vuescroll from 'vuescroll' //引入vuescroll
import 'vuescroll/dist/vuescroll.css' //引入vuescroll样式
Vue.use(vuescroll)

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import Antd from 'ant-design-vue'
import App from './App'
import 'ant-design-vue/dist/antd.css'
import * as echarts from 'echarts'
const Base64 = require('js-base64').Base64
Vue.use(Antd)
Vue.prototype.$echarts = echarts
// import VueLazyLoad from "vue-lazyload"
import store from './store/index.js'
Vue.prototype.$store = store
import Print from 'vue-print-nb'
Vue.use(Print)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  Base64,
  render: h => h(App),
}).$mount('#app')
