<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view v-if="isRouterlive"></router-view>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      locale: zhCN,
      isRouterlive: true,
    }
  },
  methods: {
    reload() {
      this.isRouterlive = false
      this.$nextTick(function () {
        this.isRouterlive = true
      })
    },
  },
}
</script>
<style>
html,
body {
  height: 100%;
  font-family: '微软雅黑';
}
#app {
  height: 100%;
  font-family: '微软雅黑';
}
</style>
