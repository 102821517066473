import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from 'store'
// import axios from "axios";
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/Login/Login'),
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('../pages/Login/Reset'),
  },
  {
    path: '/index1',
    component: () => import('../pages/Index/Index'),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    children: [
      //资产地图
      {
        path: '/assetReport',
        name: 'assetReport',
        component: () => import('../pages/main/AssetReport/index'),
      },
      // 个人中心
      {
        path: '/mine',
        name: 'mine',
        component: () => import('../pages/main/Mine/Mine'),
      },
      {
        path: '/password',
        name: 'password',
        component: () => import('../pages/main/Mine/Password'),
      },
      // 安全中心

      {
        path: '/welcome',
        name: 'welcome',
        component: () => import('../pages/main/Welcome/Welcome'),
      },
      {
        path: '/meun',
        name: 'meun',
        component: () => import('../pages/main/Meun/Meun'),
      },
      //管理员
      {
        path: '/config/enterprise',
        name: 'enterprise',
        component: () => import('../pages/main/Config/EnterPrise'),
      },
      {
        path: '/config/architecture',
        name: 'architecture',
        component: () => import('../pages/main/Config/Architecture'),
      },
      {
        path: '/config/position',
        name: 'position',
        component: () => import('../pages/main/Config/Position'),
      },
      {
        path: '/config/module',
        name: 'module',
        component: () => import('../pages/main/Config/Module'),
      },
      {
        path: '/config/params',
        name: 'params',
        component: () => import('../pages/main/Config/Params'),
      },
      {
        path: '/config/process',
        name: 'process',
        component: () => import('../pages/main/Config/Process'),
      },
      {
        path: '/config/edittemplate',
        name: 'edittemplate',
        component: () => import('../pages/main/Config/Edittemplate'),
      },
      {
        path: '/config/user',
        name: 'user',
        component: () => import('../pages/main/Config/user'),
      },
      {
        path: '/config/template',
        name: 'template',
        component: () => import('../pages/main/Config/Template'),
      },

      {
        path: '/edition/package',
        name: 'package',
        component: () => import('../pages/main/Edition/package'),
      },
      {
        path: '/edition/order',
        name: 'order',
        component: () => import('../pages/main/Edition/order'),
      },
      // 云
      {
        path: '/brands/cloud',
        name: 'cloud',
        component: () => import('../pages/main/Brandlibrary/Cloud'),
      },
      {
        path: '/brands/native',
        name: 'native',
        component: () => import('../pages/main/Brandlibrary/Native'),
      },
      //   {
      //     path: "/projectlist",
      //     name: "projectlist",
      //     component: () => import("../pages/main/Projectlibrary/Projectlist"),
      //   },
      //   {
      //     path: "/projectdetail",
      //     name: "projectdetail",
      //     component: () => import("../pages/main/Projectlibrary/Projectdetail"),
      //   },
      // 企业资产
      {
        path: '/property/project',
        name: 'project',
        component: () => import('../pages/main/Asset/Projectmanage'),
      },
      {
        path: '/property/store',
        name: 'store',
        component: () => import('../pages/main/Asset/Store/Store'),
      },
      {
        path: '/property/storeAdjust',
        name: 'storeAdjust',
        component: () => import('../pages/main/Asset/Store/storechange'),
      },
      {
        path: '/property/dj',
        name: 'dj',
        component: () => import('../pages/main/Asset/Dj'),
      },
      {
        path: '/property/ad',
        name: 'ad',
        component: () => import('../pages/main/Asset/Ad'),
      },
      // 招商规划
      {
        path: '/plan/brand',
        name: 'brand',
        component: () => import('../pages/main/Planning/Brand'),
      },
      {
        path: '/plan/plan',
        name: 'plan',
        component: () => import('../pages/main/Planning/Plan'),
      },
      // 招商实施
      {
        path: '/business/talk',
        name: 'talk',
        component: () => import('../pages/main/Business/Talk'),
      },
      {
        path: '/business/client',
        name: 'client',
        component: () => import('../pages/main/Business/Client'),
      },
      {
        path: '/business/intention',
        name: 'intention',
        component: () => import('../pages/main/Business/Intention'),
      },
      {
        path: '/business/sign',
        name: 'sign',
        component: () => import('../pages/main/Business/Sign'),
      },

      {
        path: '/business/rentMap',
        name: 'rentMap',
        component: () => import('../pages/main/Business/RentMap'),
      },

      // // 运营
      {
        path: '/operate/bill',
        name: 'bill',
        component: () => import('../pages/main/Operate/Bill'),
      },
      {
        path: '/operate/ibill',
        name: 'ibill',
        component: () => import('../pages/main/Operate/Ibill'),
      },
      {
        path: '/operate/tenant',
        name: 'tenant',
        component: () => import('../pages/main/Operate/Tenant'),
      },
      {
        path: '/operate/rent',
        name: 'rent',
        component: () => import('../pages/main/Operate/Rent'),
      },
      // { path: '/scene', name: 'scene', component: () => import('../pages/main/Operating/Scene') },
      // 财务管理
      {
        path: '/finance/bill',
        name: 'bill',
        component: () => import('../pages/main/Finance/Bill'),
      },
      {
        path: '/finance/flow',
        name: 'flow',
        component: () => import('../pages/main/Finance/Flow'),
      },
      {
        path: '/finance/billAudit',
        name: 'billAudit',
        component: () => import('../pages/main/Finance/BillAudit'),
      },
      {
        path: '/finance/zlreport',
        name: 'zlreport',
        component: () => import('../pages/main/Finance/Zlreport'),
      },
      {
        path: '/finance/ibill',
        name: 'ibill',
        component: () => import('../pages/main/Finance/Ibill'),
      },
      {
        path: '/finance/clear',
        name: 'ibill',
        component: () => import('../pages/main/Finance/clear'),
      },
      //巡检
      {
        path: '/patrol/site',
        name: 'site',
        component: () => import('../pages/main/Patrol/Site'),
      },
      {
        path: '/patrol/line',
        name: 'line',
        component: () => import('../pages/main/Patrol/Line'),
      },
      {
        path: '/patrol/plan',
        name: 'plan',
        component: () => import('../pages/main/Patrol/Plan'),
      },
      {
        path: '/patrol/task',
        name: 'task',
        component: () => import('../pages/main/Patrol/Task'),
      },
      {
        path: '/patrol/problem',
        name: 'problem',
        component: () => import('../pages/main/Patrol/Problem'),
      },
      //审批
      {
        path: '/audit/auditCenter',
        name: 'auditCenter',
        component: () => import('../pages/main/Audit/auditCenter'),
      },
      {
        path: '/audit/auditHistory',
        name: 'auditHistory',
        component: () => import('../pages/main/Audit/auditHistory'),
      },
      //小程序
      {
        path: '/xcx/config',
        name: 'config',
        component: () => import('../pages/main/xcx/config'),
      },
      {
        path: '/xcx/dashboard',
        name: 'dashboard',
        component: () => import('../pages/main/xcx/dashboard'),
      },
      //数据
      {
        path: '/security/operateHistory',
        name: 'operateHistory',
        component: () => import('../pages/main/Security/OperationHistory'),
      },
      {
        path: '/security/loginHistory',
        name: 'loginHistory',
        component: () => import('../pages/main/Security/LoginHistory'),
      },
      //支付
      {
        path: '/pay/alipay',
        name: 'alipay',
        component: () => import('../pages/main/pay/Alipay'),
      },
      //物联电表
      {
        path: '/iot/jzq',
        name: 'jzq',
        component: () => import('../pages/main/Iot/jzq'),
      },
      {
        path: '/iot/plan',
        name: 'plan',
        component: () => import('../pages/main/Iot/plan'),
      },
      {
        path: '/iot/db',
        name: 'db',
        component: () => import('../pages/main/Iot/db'),
      },
      {
        path: '/iot/billHistory',
        name: 'billHistory',
        component: () => import('../pages/main/Iot/billHistory'),
      },
      {
        path: '/iot/handleHistory',
        name: 'handleHistory',
        component: () => import('../pages/main/Iot/handleHistory'),
      },
      {
        path: '/iot/protectHistory',
        name: 'protectHistory',
        component: () => import('../pages/main/Iot/protectHistory'),
      },
    ],
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('../pages/report/Report'),
  },
]
// router.beforeEach((to, from, next) => {
//     console.log(1);
//     axios.get('/api/cscn/auth/sign/user').then(res => {
//         console.log(res);
//     })
//     if (to.meta.requireAuth) {  // 判断该路由是否需要登录权限
//         if (store.state.token) {  // 通过vuex state获取当前的token是否存在
//                 next()
//         }
//         else {
//             next({
//                 path: '/login',
//                 query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
//             })
//         }
//     }
//     else {
//         next();
//     }
// })
const router = new VueRouter({
  routes,
  mode: 'hash',
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router

// import Vue from 'vue'
// import Router from 'vue-router'

// Vue.use(Router)

// //获取原型对象上的push函数
// const originalPush = Router.prototype.push
// //修改原型对象中的push方法
// Router.prototype.push = function push (location) {
//     return originalPush.call(this, location).catch(err => err)
// }

// const baseRouters = [{
//     path: '/',
//     redirect: '/Login'
// },

// {
//     path: '/Login',
//     name: 'Login',
//     component: () =>
//         import('@/pages/Login/Login.vue')
// }
// ]

// // 需要通过后台数据来生成的组件

// const createRouter = () => new Router({
//     routes: baseRouters
// })

// const router = createRouter()

// export default router
