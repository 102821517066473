import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    openKeys: [],
    selectedKeys: [],
    projects: [],
    infos: {},
    uploadType: '',
    customerId: '',
  },
  mutations: {
    selectKeys() {
      let key = JSON.parse(localStorage.getItem('store-userlist')).id
      //   console.log(key);
      this.list = JSON.parse(localStorage.getItem(key))
      if (this.list == null || this.list == '1') {
        this.list = []
      }
      let selectkey = localStorage.getItem('selectedKeys')
      //   console.log(selectkey);
      if (selectkey == '/xcx/config') {
        selectkey = '/xcx/config'
      } else if (selectkey == '/xcx/dashboard') {
        selectkey = '/xcx/dashboard'
      } else if (selectkey == '/welcome') {
        selectkey = '/welcome'
      } else if (selectkey == '/audit/auditCenter') {
        selectkey = '/audit/auditCenter'
      } else if (selectkey == '/audit/auditHistory') {
        selectkey = '/audit/auditHistory'
      } else if (selectkey == '/business/client') {
        selectkey = '/business/client'
      } else if (selectkey == '/business/talk') {
        selectkey = '/business/talk'
      } else if (selectkey == '/business/intention') {
        selectkey = '/business/intention'
      } else if (selectkey == '/business/sign') {
        selectkey = '/business/sign'
      } else if (selectkey == '/property/project') {
        selectkey = '/property/project'
      } else if (selectkey == '/property/store') {
        selectkey = '/property/store'
      } else if (selectkey == '/property/storeAdjust?h') {
        localStorage.setItem('selectedKeys', ['/property/storeAdjust'])
        selectkey = `/property/storeAdjust?h`
      } else if (selectkey == '/property/storeAdjust?c') {
        selectkey = `/property/storeAdjust?c`
        localStorage.setItem('selectedKeys', ['/property/storeAdjust'])
      } else if (selectkey == '/property/dj ') {
        selectkey = '/property/dj '
      } else if (selectkey == '/property/ad') {
        selectkey = '/property/ad'
      } else if (selectkey == '/brands/native') {
        selectkey = '/brands/native'
      } else if (selectkey == '/plan/plan') {
        selectkey = '/plan/plan'
      } else if (selectkey == '/patrol/site') {
        selectkey = '/patrol/site'
      } else if (selectkey == '/patrol/line') {
        selectkey = '/patrol/line'
      } else if (selectkey == '/patrol/plan') {
        selectkey = '/patrol/plan'
      } else if (selectkey == '/patrol/plan?p') {
        selectkey = `/patrol/plan?p`
        localStorage.setItem('selectedKeys', ['/patrol/plan'])
      } else if (selectkey == '/operate/bill') {
        selectkey = '/operate/bill'
      } else if (selectkey == '/operate/ibill') {
        selectkey = '/operate/ibill'
      } else if (selectkey == '/operate/tenant') {
        selectkey = '/operate/tenant'
      } else if (selectkey == '/finance/bill') {
        selectkey = '/finance/bill'
      } else if (selectkey == '/finance/flow') {
        selectkey = '/finance/flow'
      } else if (selectkey == '/finance/ibill') {
        selectkey = '/finance/ibill'
      } else if (selectkey == '/finance/zlreport') {
        selectkey = '/finance/zlreport'
      } else {
        return
      }
      let index = this.list.indexOf(selectkey)
      //   console.log(index);
      if (index != -1) {
        this.list.splice(index, 1)
      }
      if (this.list.length < 6) {
        this.list.push(selectkey)
      } else {
        this.list.splice(0, 1)
        this.list.push(selectkey)
      }
      //   console.log(this.list);
      localStorage.setItem(key, JSON.stringify(this.list))
    },
  },
})
